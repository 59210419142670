import React, { useState, useEffect } from 'react';
import { StyledSpinnerNext } from "baseui/spinner";
import logo from './../NM_logo.png';



import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem
} from "baseui/header-navigation";

import {
  Label1,
  Label2,
  Label4,
} from 'baseui/typography';

import {
  Unstable_StatefulDataTable,
  CategoricalColumn,
  NumericalColumn,
} from 'baseui/data-table';

import {Table} from 'baseui/table-semantic';


import { StyledLink } from "baseui/link";


import { useStyletron } from 'baseui';

import './Home.css';


const monthMap = {
  "3": "Mar",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "Aug",
  "9": "Sep",
  // corono will be over
}
function addCommas(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

const columns = [
  CategoricalColumn({
    title: 'Country',
    mapDataToValue: (data) => data.Country,
  }),
  NumericalColumn({
    title: 'Confirmed',
    mapDataToValue: (data) => data.TotalConfirmed,
  }),
  NumericalColumn({
    title: 'Active',
    mapDataToValue: (data) => data.TotalActive,
  }),
  NumericalColumn({
    title: 'Recovered',
    mapDataToValue: (data) => data.TotalRecovered,
  }),
  NumericalColumn({
    title: 'Deceased',
    mapDataToValue: (data) => data.TotalDeaths,
  })
]


function Home() {
  const [countriesData, setcountriesData] = useState([]);
  const [indiaCases, setIndiaCases] = useState({});
  const [worldCases, setWorldCases] = useState({})
  const [indstateData, setIndStateData] = useState([])
  const [indConfTimelineData, setIndConfTimelineData] = useState([])
  const [error, setError] = useState(false);
  const [css] = useStyletron();

  const extraMarginContent = css({ marginTop: '25px', marginBottom: '10px' })
  const smallMargin = css({ marginTop: '6px' })
  const pageMargin = css({ width: '95vw', margin: 'auto' })
  const timelineWrapper = css({
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    height: '100px',
    marginBottom: '20px',
    width: '100%',
  })


  const timelineCard = css({
    display: 'inline-block',
    border: '2px solid lightgray',
    width: '50px',
    height: '60px',
    background: '#fff',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '10px',
    padding: '5px',
  })
  useEffect(() => {
    // Update the document title using the browser API
    let countries = []
    
    fetch("https://corona.lmao.ninja/v2/countries")
      .then(res => res.json())
      .then(
        (result) => {
          let ws = {
            TotalConfirmed: 0,
            NewConfirmed: 0,
            TotalRecovered: 0,
            NewRecovered: 0,
            TotalDeaths: 0,
            NewDeaths: 0,
            TotalActive: 0,
          }
          for (let country of result) {
            ws.TotalConfirmed += country.cases
            ws.TotalRecovered += country.recovered
            ws.TotalDeaths += country.deaths
            ws.TotalActive += country.active
            ws.NewConfirmed += country.todayCases
            ws.NewDeaths += country.todayDeaths

            countries.push({
              id: country.countryInfo._id,
              data: {
                Country: country.country,
                TotalConfirmed: country.cases,
                TotalRecovered: country.recovered,
                TotalDeaths: country.deaths,
                TotalActive: country.active,
                NewConfirmed: country.todayCases,
                NewRecovered: 0,
                NewDeaths: country.todayDeaths,
              }
            })

            if (country.country === 'India'){
              setIndiaCases({
                TotalConfirmed: country.cases,
                TotalRecovered: country.recovered,
                TotalDeaths: country.deaths,
                NewConfirmed: country.todayCases,
                NewRecovered: 0,
                NewDeaths: country.todayDeaths,
                TotalActive: country.active,
                TotalRecoveredPer: parseFloat((country.recovered / country.cases) * 100).toFixed(2),
                TotalDeathsPer: parseFloat((country.deaths / country.cases) * 100).toFixed(2),
              })
            }
          }
          countries.sort((a, b) => (a.data.TotalConfirmed < b.data.TotalConfirmed) ? 1 : -1)
          ws.TotalRecoveredPer = parseFloat((ws.TotalRecovered / ws.TotalConfirmed) * 100).toFixed(2)
          ws.TotalDeathsPer = parseFloat((ws.TotalDeaths / ws.TotalConfirmed) * 100).toFixed(2)
          setWorldCases(ws)
          setcountriesData(countries)
        },
        (error) => {
          setError(true)
        }
      )
      
      fetch("https://indiatoday.front10.cloud/")
      .then(res => res.json())
      .then(
        (result) => {
          setIndStateData(result.otherStats.usStateStats)
        },
        (error) => {
          setError(true)
        }
      )

      fetch("https://corona.lmao.ninja/v2/historical/india")
      .then(res => res.json())
      .then(
        (result) => {
          let indTimeLine = result.timeline.cases
          let tl = []
          let prev = 0
          for (let key in indTimeLine) {
            let value = indTimeLine[key];
            const splitDate = key.split('/')
            const dateStr = splitDate[1] + ' ' + monthMap[splitDate[0]]
            tl.push({'date': dateStr, 'val': value - prev})
            prev = value
          }
          tl.reverse()
          setIndConfTimelineData(tl)
        },
        (error) => {
          setError(true)
        }
      )
  }, []);

  let indiaDisplay = '';
  let worldDisplay = ''
  let tableDisplay = (
    <div className={css({ justifyContent: 'center', display: 'flex', marginTop: '30vh' })}>
      <StyledSpinnerNext size="100px" /></div>);

  if (worldCases.TotalConfirmed) {
    worldDisplay = (
      <div className={css({ width: '95vw', margin: 'auto' })}>
        <Label4 color='green' className={smallMargin}>As of Date: <b>{Date().slice(0,15)}</b></Label4>
        <Label1 className={extraMarginContent}><span role="img" aria-label="Globe">🌎</span>World</Label1>
        <div className={css({ justifyContent: 'space-between', display: 'flex' })}>
          <div>
            <Label2 color='red' className={smallMargin}>Confirmed</Label2>
            <Label2 color='red' className={smallMargin}>{addCommas(worldCases.TotalConfirmed)}</Label2>
            <Label4 color='red' className={smallMargin}>[{worldCases.NewConfirmed && '+'}{addCommas(worldCases.NewConfirmed)}]</Label4>
          </div>
          <div>
            <Label2 color='#008ae6' className={smallMargin}>Active</Label2>
            <Label2 color='#008ae6' className={smallMargin}>{addCommas(worldCases.TotalActive)}</Label2>
          </div>
          <div >
            <Label2 color='green' className={smallMargin}>Recovered</Label2>
            <Label2 color='green' className={smallMargin}>{addCommas(worldCases.TotalRecovered)}</Label2>
            <Label4 color='green' className={smallMargin}>{worldCases.TotalRecoveredPer} %</Label4>
          </div>
          <div>
            <Label2 color='gray' className={smallMargin}>Deceased</Label2>
            <Label2 color='gray' className={smallMargin}>{addCommas(worldCases.TotalDeaths)}</Label2>
            <Label4 color='gray' className={smallMargin}>[{worldCases.NewDeaths && '+'}{addCommas(worldCases.NewDeaths)}]</Label4>
            <Label4 color='gray' className={smallMargin}>{worldCases.TotalDeathsPer} %</Label4>
          </div>
        </div>
      </div>
    )
  }

  if (indiaCases.TotalConfirmed) {
    indiaDisplay = (
      <div className={pageMargin}>
        <Label1 className={extraMarginContent}><span role="img" aria-label="flag">🇮🇳</span>India</Label1>
        <div className={css({ justifyContent: 'space-between', display: 'flex' })}>
          <div>
            <Label2 color='red' className={smallMargin}>Confirmed</Label2>
            <Label2 color='red' className={smallMargin}>{addCommas(indiaCases.TotalConfirmed)}</Label2>
            <Label4 color='red' className={smallMargin}>[{indiaCases.NewConfirmed && '+'}{addCommas(indiaCases.NewConfirmed)}]</Label4>
          </div>
          <div>
            <Label2 color='#008ae6' className={smallMargin}>Active</Label2>
            <Label2 color='#008ae6' className={smallMargin}>{addCommas(indiaCases.TotalActive)}</Label2>
          </div>
          <div >
            <Label2 color='green' className={smallMargin}>Recovered</Label2>
            <Label2 color='green' className={smallMargin}>{addCommas(indiaCases.TotalRecovered)}</Label2>
            <Label4 color='green' className={smallMargin}>{indiaCases.TotalRecoveredPer} %</Label4>
          </div>
          <div>
            <Label2 color='gray' className={smallMargin}>Deceased</Label2>
            <Label2 color='gray' className={smallMargin}>{addCommas(indiaCases.TotalDeaths)}</Label2>
            <Label4 color='gray' className={smallMargin}>[{indiaCases.NewDeaths && '+'}{addCommas(indiaCases.NewDeaths)}]</Label4>
            <Label4 color='gray' className={smallMargin}>{indiaCases.TotalDeathsPer} %</Label4>
          </div>
        </div>
      </div>
    )
  }

  let indTlData = []
  for(let item of indConfTimelineData){
    indTlData.push(
    <div className={timelineCard} id={item.date}>
      <Label4 color="#484D6D" >{item.date}</Label4>
      <Label2 color="#EE6352" className={css({marginTop: '10px'})}><b>+{item.val}</b></Label2>
    </div>
    )
  }

  const indiaConfTimeline = (
    <div>
    <Label2 className={extraMarginContent, pageMargin}>Date wise Confirmed Cases(India)</Label2>
    <div className={timelineWrapper}>
      {indTlData}
    </div>
    </div>
  )

  if (countriesData.length > 0) {
    tableDisplay = (
      <div className={css({marginTop: '20px'})}>
      <Label1 className={extraMarginContent, pageMargin}>All Countries</Label1>
      <div className={css({ height: '85vh' })}>
        <Unstable_StatefulDataTable columns={columns} rows={countriesData} />
      </div>
      </div>)
  }

  let indStateDisplay = ''

  if (indstateData.length > 0){
    indStateDisplay = (<Table className={css({border: '1px solid black'})}
      columns={["State", "Con", "Rec", "Dec"]}
      data={indstateData.map(x=>[
      <span>{x.State}</span>,
      <span className={css({ textAlign: 'right', color: 'red' })}>{x.Confirmed}</span>,
      <span className={css({ textAlign: 'right', color: 'green' })}>{x.Recovered}</span>,
      <span className={css({ textAlign: 'right', color: 'gray' })}>{x.Deaths}</span>]
      )}
    />)

    indStateDisplay = <div className={pageMargin}><Label1 className={extraMarginContent}>India State wise</Label1>{indStateDisplay}</div>
  }

  return (
    <div>
      <HeaderNavigation>
        <StyledNavigationList $align={ALIGN.center}>
          <StyledNavigationItem>
            <img src={logo} height="32px" />
          </StyledNavigationItem>
          <StyledNavigationItem style={{ paddingLeft: '10px' }}>
            <StyledLink
              className={css({ textDecoration: 'None' })}
              href="/">
              COVID-19 TRACKER</StyledLink></StyledNavigationItem>
        </StyledNavigationList>
      </HeaderNavigation>

      {worldDisplay}

      {indiaDisplay}

      {indiaConfTimeline}

      {indStateDisplay}

      {tableDisplay}

      {error && <Label2 className={smallMargin}>Something went wrong. Please try again</Label2>}

      <div className={css({ justifyContent: 'center', display: 'flex', marginTop: '20px', marginBottom: '20px' })}>
        Powered by&nbsp;
        <StyledLink href="http://netmusk.com" target="_blank" color="blue"
        >Netmusk</StyledLink>
      </div>

    </div>
  );
}

export default Home;
